var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v(" List Your Company "),(_vm.site)?_c('span',[_vm._v("in the "+_vm._s(_vm.site.sitename))]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"typeahead-container mb-3"},[_c('div',{staticClass:"typeahead-input form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"company-name"}},[_vm._v(" Company Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.name),expression:"company.name"}],ref:"companyName",class:[
                  'form-control',
                  _vm.companyValidation.name.valid
                    ? _vm.companyValidation.name.dirty
                      ? 'is-valid is-valid-lite'
                      : ''
                    : 'is-invalid' ],attrs:{"id":"company-name","type":"text"},domProps:{"value":(_vm.company.name)},on:{"focus":function($event){_vm.showTypeahead = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "name", $event.target.value)}}})]),(_vm.showTypeahead)?_c('div',{staticClass:"typeahead-list list-group shadow mb-3"},_vm._l((_vm.typeaheadArr),function(res){return _c('a',{key:res.domain,staticClass:"list-group-item list-group-item-action",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.updateCompany(res)}}},[_c('img',{staticStyle:{"width":"24px"},attrs:{"src":res.logo}}),_vm._v(" "+_vm._s(res.name)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"company-website"}},[_vm._v(" Company Website ")]),_c('div',{staticClass:"input-group has-validation"},[_c('span',{staticClass:"input-group-text"},[_vm._v("https://")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.url),expression:"company.url"}],ref:"companyWebsite",class:[
                  'form-control',
                  _vm.companyValidation.url.valid
                    ? _vm.companyValidation.url.dirty
                      ? 'is-valid is-valid-lite'
                      : ''
                    : 'is-invalid' ],attrs:{"id":"company-website","type":"text"},domProps:{"value":(_vm.company.url)},on:{"blur":_vm.validateDomain,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "url", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid domain.")])])])]),(_vm.company.logo)?_c('div',{staticClass:"col-md-4 text-center"},[(_vm.company.logo)?_c('img',{staticClass:"mb-2",staticStyle:{"max-width":"96px"},attrs:{"src":_vm.company.logo}}):_vm._e(),_c('p',{staticClass:"small text-muted"},[_vm._v(_vm._s(_vm.company.name))])]):_vm._e()])])]),(_vm.companyFilled)?_c('category-select',{attrs:{"site":_vm.site.siteid},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}}):_vm._e(),(_vm.categories.length >= 1)?_c('competitor-search',{model:{value:(_vm.competitors),callback:function ($$v) {_vm.competitors=$$v},expression:"competitors"}}):_vm._e(),(_vm.companyFilled && _vm.categories.length >= 1)?_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('button',{staticClass:"btn btn-primary btn-md",attrs:{"disabled":!_vm.companyValid},on:{"click":_vm.saveCompany}},[_vm._v(" List Your Company ")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }