<template>
  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="row row-cards">
            <div class="col-12" v-if="site && siteSeo">
              <div class="card">
                <div class="card-body text-center">
                  <a :href="`https://${site.directoryurl}/`" target="_blank">
                    <img
                      :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
                    />
                  </a>

                  <p class="subheader">{{ site.sitename }}</p>
                </div>
              </div>
            </div>
            <div class="col-12" v-if="site && siteSeo">
              <div class="card">
                <div class="card-body">
                  <p class="">
                    <strong>Start Reaching your Target Audience</strong>
                  </p>
                  <p>
                    {{ site.sitename }} is powered by MediaBrains, a leading B2B
                    marketplace platform that serves millions of professionals
                    in over 20 industries.
                  </p>
                  <p>
                    Companies listed in the {{ site.sitename }} are reaching
                    {{ traffic }}
                    industry professionals every year.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <p>
                    <strong>Why list your company with MediaBrains?</strong>
                  </p>

                  <ul>
                    <li>
                      Feature your company’s most relevant industry content to
                      drive traffic to your website and generate leads
                    </li>
                    <li>
                      Get access to robust buyer intent data from our Advertiser
                      Center, which delivers visitor insights and audience
                      reporting
                    </li>
                    <li>
                      Our experts are here to help you identify the best
                      marketing solutions to meet your individual needs, while
                      offering ongoing support
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <p class="text-center">
              <a
                class="text-muted"
                style="font-size: 12px"
                href="https://clearbit.com"
                target="_blank"
              >
                Company logos provided by ClearBit
              </a>
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <company-form
            v-model="formInfo"
            @save-company="saveCompany"
            :site="site"
            v-if="step == 'form'"
          ></company-form>
          <verify-form v-else-if="step == 'verify'"></verify-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyForm from "../../components/AddYourCompany/CompanyForm.vue";
import VerifyForm from "../../components/AddYourCompany/VerifyForm.vue";
import SiteService from "../../services/SiteService";

export default {
  components: { CompanyForm, VerifyForm },
  name: "addyourcompany.index",
  data() {
    return {
      site: null,
      siteSeo: null,

      formInfo: null,
      step: "form",
    };
  },

  methods: {
    saveCompany() {
      console.log("Save company");
      this.step = "verify";
    },
  },
  computed: {
    traffic() {
      if (this.site.traffic) {
        var amount = parseInt(
          this.site.traffic.split("-")[1].trim()
        ).toLocaleString();
        return `up to ${amount}`;
      } else {
        return "";
      }
    },
  },
  async mounted() {
    if (this.$route.query.site) {
      let siteId = this.$route.query.site;
      this.site = await SiteService.getSite(siteId);
      this.siteSeo = await SiteService.getSiteSeo(siteId);
    }
  },
};
</script>
