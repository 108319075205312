<template>
  <div class="card mb-3">
    <div class="card-body">
      <h3>Verify Contact Info</h3>
      <div class="row">
        <div class="col-md-8">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="first-name" class="form-label"> First Name </label>
                <input
                  id="first-name"
                  type="text"
                  :class="[
                    'form-control',
                    contactValidation.firstName.valid
                      ? contactValidation.firstName.dirty
                        ? 'is-valid is-valid-lite'
                        : ''
                      : 'is-invalid',
                  ]"
                  ref="firstName"
                  @blur="validateFirstName"
                  v-model="contact.firstName"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="last-name" class="form-label"> Last Name </label>
                <input
                  id="last-name"
                  type="text"
                  :class="[
                    'form-control',
                    contactValidation.lastName.valid
                      ? contactValidation.lastName.dirty
                        ? 'is-valid is-valid-lite'
                        : ''
                      : 'is-invalid',
                  ]"
                  @blur="validateLastName"
                  v-model="contact.lastName"
                />
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label for="work-email" class="form-label">
              Work Email Address
            </label>
            <input
              id="work-email"
              type="text"
              :class="[
                'form-control',
                contactValidation.email.valid
                  ? contactValidation.email.dirty
                    ? 'is-valid is-valid-lite'
                    : ''
                  : 'is-invalid',
              ]"
              @blur="validateEmail"
              v-model="contact.email"
            />
          </div>

          <div class="form-group">
            <label for="work-phone" class="form-label">Phone</label>

            <vue-tel-input
              v-model="contact.phone"
              :validCharactersOnly="true"
              :dropdownOptions="{
                showDialCodeInSelection: true,
                showDialCodeInList: true,
                showFlags: true,
                showSearchBox: false,
              }"
              :inputOptions="{
                autofocus: true,
                showDialCode: false,
                required: true,
                styleClasses: [
                  'form-control',
                  contactValidation.phone.valid
                    ? contactValidation.phone.dirty
                      ? 'is-valid is-valid-lite'
                      : ''
                    : 'is-invalid',
                ],
                name: 'phone',
                id: 'work-phone',
              }"
              :styleClasses="['input-group', 'has-validation']"
              :preferredCountries="['US', 'CA', 'GB', 'IN', 'CN']"
              @blur="validatePhone"
            >
              <template v-slot:arrow-icon> <span></span> </template>
            </vue-tel-input>
          </div>
        </div>
        <div class="col-md-4">
          <p class="text-muted">
            Providing accurate contact information is important to ensure our
            placement team can add your company to a MediaBrains directory.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input"),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input/dist/vue-tel-input.css"
    ),
  ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: "add-your-company.contact-information-form",
  components: {
    VueTelInput,
  },
  data() {
    return {
      contact: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      },

      contactValidation: {
        firstName: {
          dirty: false,
          valid: true,
          warning: null,
        },
        lastName: {
          dirty: false,
          valid: true,
          warning: null,
        },
        email: {
          dirty: false,
          valid: true,
          warning: null,
        },
        phone: {
          dirty: false,
          valid: true,
          warning: null,
        },
      },
    };
  },
  computed: {
    contactValid() {
      return (
        this.contactValidation.firstName.valid &&
        this.contactValidation.lastName.valid &&
        this.contactValidation.email.valid &&
        this.contactValidation.phone.valid &&
        this.contactValidation.firstName.dirty &&
        this.contactValidation.lastName.dirty &&
        this.contactValidation.email.dirty &&
        this.contactValidation.phone.dirty
      );
    },
  },
  methods: {
    validateFirstName() {
      this.contactValidation.firstName.dirty = true;
      if (!this.contact.firstName || this.contact.firstName == "") {
        this.contactValidation.firstName.valid = false;
        return;
      }
      this.contact.firstName = this.contact.firstName.toLowerCase();
      this.contact.firstName =
        this.contact.firstName.charAt(0).toUpperCase() +
        this.contact.firstName.slice(1);
      this.contactValidation.firstName.valid = true;
    },
    validateLastName() {
      this.contactValidation.lastName.dirty = true;
      if (!this.contact.lastName || this.contact.lastName == "") {
        this.contactValidation.lastName.valid = false;
        return;
      }
      this.contact.lastName =
        this.contact.lastName.charAt(0).toUpperCase() +
        this.contact.lastName.slice(1);
      this.contactValidation.lastName.valid = true;
    },
    validateEmail() {
      this.contactValidation.email.dirty = true;
      if (!this.contact.email || this.contact.email == "") {
        this.contactValidation.email.valid = false;
        return;
      }
      const regex = /^\S+@.+$/g;
      this.contact.email = this.contact.email.trim();
      if (!this.contact.email.match(regex)) {
        this.contactValidation.email.valid = false;
        this.contactValidation.email.warning =
          "Please enter a valid email address: example@example.com";
        return;
      }

      // let generic_emails = [
      //   "info@",
      //   "contact@",
      //   "noreply@",
      //   "help@",
      //   "marketing@",
      //   "about@",
      //   "hello@",
      //   "hi@",
      //   "support@",
      //   "spam@",
      // ];
      this.contactValidation.email.valid = true;
    },
    validatePhone() {
      this.contactValidation.phone.dirty = true;
      this.contactValidation.phone.valid = true;
    },
  },
};
</script>
