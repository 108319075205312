<template>
  <div>
    <div class="card mb-3">
      <div class="card-status-top bg-warning"></div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h3>
              <span class="text-warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-alert-triangle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 9v2m0 4v.01"></path>
                  <path
                    d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                  ></path>
                </svg>
              </span>
              You're almost done adding your company
            </h3>
            <p class="mb-0">
              At MediaBrains, we take pride in our directories providing a high
              quality user experience for buyers. In order to ensure your
              company is a good fit for our advertising network, our team needs
              to verify your identity.
            </p>
          </div>
        </div>
      </div>
    </div>

    <contact-information-form></contact-information-form>
  </div>
</template>

<script>
import ContactInformationForm from "./ContactInformationForm.vue";
export default {
  name: "add-your-company.verify-form",
  components: {
    ContactInformationForm,
  },
};
</script>
