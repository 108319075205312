<template>
  <div class="mb-3" v-if="!value.complete">
    <div class="typeahead-container" v-if="!custom">
      <div class="typeahead-input form-group">
        <label :for="`company-name-${uniqueId}`" class="form-label">
          {{ label }}
        </label>
        <div class="input-group input-group-flat">
          <input
            :id="`company-name-${uniqueId}`"
            type="text"
            :class="['form-control']"
            ref="companyName"
            v-model="value.name"
            @blur="unfocus"
            @focus="showTypeahead = true"
          />

          <span class="input-group-text" v-if="showTypeahead">
            <a href="#" @click.prevent="clear" class="link-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </a>
          </span>
        </div>
      </div>

      <div
        class="typeahead-list list-group shadow mb-3"
        ref="typeaheadList"
        v-if="showTypeahead"
      >
        <a
          href="#"
          class="list-group-item list-group-item-action"
          v-for="res in typeaheadArr"
          v-bind:key="res.domain"
          @click.capture="updateCompany(res)"
          tabindex="-1"
        >
          <img :src="res.logo" style="width: 24px" />
          {{ res.name }}
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action text-muted"
          v-if="value.name && value.name.length > 0"
          @click.prevent="addCustom"
          tabindex="-1"
        >
          Don't see the company you're looking for? Enter it yourself.
        </a>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-5">
        <label :for="`company-name-${uniqueId}`" class="form-label">
          {{ label }}
        </label>
        <input
          class="form-control"
          :id="`company-name-${uniqueId}`"
          v-model="value.name"
          ref="companyName"
        />
      </div>
      <div class="col-md-5">
        <label :for="`company-url-${uniqueId}`" class="form-label"> URL </label>
        <div class="input-group has-validation">
          <span class="input-group-text">https://</span>
          <input
            :id="`company-url-${uniqueId}`"
            ref="companyWebsite"
            class="form-control"
            type="text"
            v-model="value.url"
          />
          <div class="invalid-feedback">Please enter a valid domain.</div>
        </div>
      </div>
      <div class="col-md-2">
        <label
          :for="`company-url-${uniqueId}`"
          class="form-label"
          style="color: white"
        >
          Save
        </label>

        <button @click="saveCompany" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
  <div class="mb-3" v-else>
    <div class="border rounded d-flex align-items-center p-2">
      <img
        v-if="value.logo"
        :src="value.logo"
        class="me-4 rounded"
        style="max-width: 36px"
      />
      <strong class="me-4">{{ value.name }}</strong>
      <a :href="`https://${value.url}`" class="me-auto" target="_blank">
        https://{{ value.url }}
      </a>

      <a
        href="#"
        class="btn btn-outline-primary btn-icon me-2"
        @click.prevent="change"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-edit"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
          ></path>
          <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
          <line x1="16" y1="5" x2="19" y2="8"></line>
        </svg>
      </a>
      <a
        href="#"
        class="btn btn-outline-danger btn-icon"
        @click.prevent="remove"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-trash"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="4" y1="7" x2="20" y2="7"></line>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
        </svg>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.typeahead-container {
  position: relative;

  .typeahead-list {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 100;
  }
}
</style>

<script>
const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export default {
  name: "add-your-company.typeahead-company-input",
  props: {
    value: Object,
    label: String,
    uniqueId: Number,
  },
  data() {
    return {
      typeaheadArr: [],
      showTypeahead: false,
      custom: false,
      fromProvider: false,
    };
  },
  methods: {
    addCustom() {
      this.custom = true;
      this.$refs.companyName.focus();
    },
    remove() {
      this.$emit("remove");
    },
    change() {
      if (!this.fromProvider) {
        this.custom = true;
      }
      this.value.complete = false;
    },
    unfocus(event) {
      if (
        event.relatedTarget &&
        this.$refs.typeaheadList.contains(event.relatedTarget)
      ) {
        console.log("allow");
      } else {
        this.showTypeahead = false;
      }
    },
    clear() {
      this.$emit("input", {
        id: this.uniqueId,
        name: null,
        url: null,
        logo: null,
        complete: false,
      });
      this.showTypeahead = false;
    },
    saveCompany() {
      this.fromProvider = false;
      this.showTypeahead = false;
      this.custom = false;
      this.value.id = this.uniqueId;
      this.value.logo = null;
      this.value.complete = true;
    },
    updateCompany(company) {
      this.fromProvider = true;
      this.showTypeahead = false;
      this.$emit("input", {
        id: this.uniqueId,
        name: company.name,
        url: company.domain,
        logo: `${company.logo}?size=800`,
        complete: true,
      });
    },
    async typeahead() {
      if (!this.value.name || this.value.name.length < 1) {
        this.typeaheadArr = [];
        return;
      }

      let typeaheadResults = await fetch(
        `https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(
          this.value.name
        )}`
      ).then((resp) => resp.json());

      this.typeaheadArr = typeaheadResults;
    },
  },
  watch: {
    "value.name": debounce(function () {
      this.typeahead();
    }, 100),
  },
};
</script>
