<template>
  <div class="card mb-3">
    <div class="card-body">
      <h3>Identify Your Competitors</h3>
      <p class="text-muted">
        By adding up to three competitors, we will ensure you are placed in the
        most relevant categories. Additionally, adding competitors will unlock
        additional competitive insights in your reports.
      </p>

      <typeahead-company-input
        v-for="(competitor, idx) in value"
        :key="`competitor-${competitor.id}`"
        :label="`Competitor Name`"
        :unique-id="competitor.id"
        v-model="value[idx]"
        @remove="remove(idx)"
      ></typeahead-company-input>

      <button
        :class="['btn', 'btn-outline', 'mt-1']"
        @click="addCompetitor"
        v-if="canAddCompetitor"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="9" y1="12" x2="15" y2="12" />
          <line x1="12" y1="9" x2="12" y2="15" />
        </svg>
        Add another competitor
      </button>
    </div>
  </div>
</template>

<script>
import TypeaheadCompanyInput from "./TypeaheadCompanyInput.vue";
export default {
  name: "add-your-company.competitor-search",
  props: {
    value: Array,
  },
  data() {
    return {
      companyId: 0,
    };
  },
  components: {
    TypeaheadCompanyInput,
  },
  computed: {
    canAddCompetitor() {
      return (
        this.value.length == 0 ||
        (this.value[this.value.length - 1].complete && this.value.length < 3)
      );
    },
  },
  methods: {
    remove(index) {
      this.value.splice(index, 1);
    },
    addCompetitor() {
      this.value.push({
        id: this.companyId,
        name: null,
        url: null,
        logo: null,
        complete: false,
      });
      this.companyId++;
    },
  },
  mounted() {
    this.addCompetitor();
  },
};
</script>
