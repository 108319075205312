var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Verify Contact Info")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"first-name"}},[_vm._v(" First Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.firstName),expression:"contact.firstName"}],ref:"firstName",class:[
                  'form-control',
                  _vm.contactValidation.firstName.valid
                    ? _vm.contactValidation.firstName.dirty
                      ? 'is-valid is-valid-lite'
                      : ''
                    : 'is-invalid' ],attrs:{"id":"first-name","type":"text"},domProps:{"value":(_vm.contact.firstName)},on:{"blur":_vm.validateFirstName,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "firstName", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"last-name"}},[_vm._v(" Last Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.lastName),expression:"contact.lastName"}],class:[
                  'form-control',
                  _vm.contactValidation.lastName.valid
                    ? _vm.contactValidation.lastName.dirty
                      ? 'is-valid is-valid-lite'
                      : ''
                    : 'is-invalid' ],attrs:{"id":"last-name","type":"text"},domProps:{"value":(_vm.contact.lastName)},on:{"blur":_vm.validateLastName,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "lastName", $event.target.value)}}})])])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"work-email"}},[_vm._v(" Work Email Address ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.email),expression:"contact.email"}],class:[
              'form-control',
              _vm.contactValidation.email.valid
                ? _vm.contactValidation.email.dirty
                  ? 'is-valid is-valid-lite'
                  : ''
                : 'is-invalid' ],attrs:{"id":"work-email","type":"text"},domProps:{"value":(_vm.contact.email)},on:{"blur":_vm.validateEmail,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "email", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"work-phone"}},[_vm._v("Phone")]),_c('vue-tel-input',{attrs:{"validCharactersOnly":true,"dropdownOptions":{
              showDialCodeInSelection: true,
              showDialCodeInList: true,
              showFlags: true,
              showSearchBox: false,
            },"inputOptions":{
              autofocus: true,
              showDialCode: false,
              required: true,
              styleClasses: [
                'form-control',
                _vm.contactValidation.phone.valid
                  ? _vm.contactValidation.phone.dirty
                    ? 'is-valid is-valid-lite'
                    : ''
                  : 'is-invalid' ],
              name: 'phone',
              id: 'work-phone',
            },"styleClasses":['input-group', 'has-validation'],"preferredCountries":['US', 'CA', 'GB', 'IN', 'CN']},on:{"blur":_vm.validatePhone},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('span')]},proxy:true}]),model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}})],1)]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"text-muted"},[_vm._v(" Providing accurate contact information is important to ensure our placement team can add your company to a MediaBrains directory. ")])])}]

export { render, staticRenderFns }