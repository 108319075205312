<template>
  <div :class="['row', 'border', 'p-2', 'rounded', 'mb-1', 'ms-0']">
    <div class="col-md-5" v-if="!saved">
      <select ref="parentSelect" class="form-select" v-model="selected_parent">
        <option :value="null" disabled>Select a category</option>
        <option
          v-for="category in parent_categories"
          :key="category.categoryid"
          :value="category"
        >
          {{ category.categoryname }}
        </option>
      </select>
    </div>

    <div
      class="col-md-4"
      v-if="!saved && child_categories && child_categories.length > 0"
    >
      <select class="form-select" v-model="selected_child">
        <option :value="null" disabled>Select a sub-category</option>

        <option
          v-for="category in child_categories"
          :key="category.categoryid"
          :value="category"
        >
          {{ category.categoryname }}
        </option>
      </select>
    </div>

    <div class="col-md-12" v-if="saved">
      <div class="d-flex align-items-center">
        <p class="me-auto mb-0">
          {{ selected_parent.categoryname }}
          <span v-if="selected_child">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-arrow-right"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <line x1="13" y1="18" x2="19" y2="12"></line>
              <line x1="13" y1="6" x2="19" y2="12"></line>
            </svg>

            {{ selected_child.categoryname }}
          </span>
        </p>

        <a
          href="#"
          class="btn btn-outline-primary btn-icon me-2"
          @click.prevent="change"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-edit"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
            ></path>
            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
            <line x1="16" y1="5" x2="19" y2="8"></line>
          </svg>
        </a>
        <a
          href="#"
          class="btn btn-outline-danger btn-icon"
          @click.prevent="remove"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-trash"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="4" y1="7" x2="20" y2="7"></line>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
          </svg>
        </a>
      </div>
    </div>

    <div class="col-md-3" v-if="!saved && done">
      <button class="btn btn-primary" @click="save">Save Selection</button>
    </div>
  </div>
</template>

<script>
import CategoryService from "../../services/CategoryService";

export default {
  name: "add-your-company.add-category-row",
  props: {
    site: Number,
    value: Object,
    autofocus: Boolean,
  },
  data() {
    return {
      parent_categories: null,
      selected_parent: null,
      selected_child: null,
      child_categories: null,
      saved: false,
      loaded: false,
    };
  },
  computed: {
    done() {
      if (!this.selected_parent) return false;
      if (!this.loaded) return false;
      if (this.child_categories && this.child_categories.length > 0) {
        if (!this.selected_child) return false;
      }
      return true;
    },
  },
  methods: {
    save() {
      this.saved = true;
      this.$emit("input", {
        id: this.value.id,
        parent: this.selected_parent,
        child: this.selected_child,
      });
    },
    change() {
      this.saved = false;
    },
    remove() {
      this.$emit("remove");
    },
  },
  watch: {
    selected_parent: async function () {
      this.loaded = false;
      this.child_categories = await CategoryService.getSubcategories(
        this.selected_parent.categoryid
      );
      this.loaded = true;
      this.selected_child = null;
    },
  },
  async mounted() {
    this.parent_categories = await CategoryService.getSiteCategories(170);
  },
};
</script>
