<template>
  <div class="card mb-3">
    <div class="card-body">
      <h3>Pick Your Top Categories</h3>
      <p class="text-muted">
        Choose up to 3 categories where your company would be a great fit.
      </p>

      <add-category-row
        v-for="(category, idx) in value"
        :key="`category-${category.id}`"
        v-model="value[idx]"
        @remove="remove(idx)"
      ></add-category-row>

      <button
        :class="['btn', 'btn-outline', 'mt-1']"
        @click="addCategory"
        v-if="canAddCategory"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="9" y1="12" x2="15" y2="12" />
          <line x1="12" y1="9" x2="12" y2="15" />
        </svg>
        Add another category
      </button>
    </div>
  </div>
</template>

<script>
import AddCategoryRow from "./AddCategoryRow.vue";

export default {
  name: "add-your-company.category-select",
  components: {
    AddCategoryRow,
  },
  props: {
    value: Array,
    site: Number,
  },
  data() {
    return {
      categoryId: 0,
    };
  },
  computed: {
    canAddCategory() {
      return (
        this.value.filter((c) => !c.parent).length == 0 && this.value.length < 3
      );
    },
  },
  methods: {
    addCategory() {
      this.value.push({
        id: this.categoryId,
      });
      this.categoryId += 1;
    },
    remove(index) {
      this.value.splice(index, 1);
    },
  },
  mounted() {
    this.addCategory();
  },
};
</script>
