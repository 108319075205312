<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <h3>
          List Your Company
          <span v-if="site">in the {{ site.sitename }}</span>
        </h3>
        <div class="row">
          <div class="col-md-8">
            <div class="typeahead-container mb-3">
              <div class="typeahead-input form-group">
                <label for="company-name" class="form-label">
                  Company Name
                </label>
                <input
                  id="company-name"
                  type="text"
                  :class="[
                    'form-control',
                    companyValidation.name.valid
                      ? companyValidation.name.dirty
                        ? 'is-valid is-valid-lite'
                        : ''
                      : 'is-invalid',
                  ]"
                  ref="companyName"
                  v-model="company.name"
                  @focus="showTypeahead = true"
                />
              </div>

              <div
                class="typeahead-list list-group shadow mb-3"
                v-if="showTypeahead"
              >
                <a
                  href="#"
                  class="list-group-item list-group-item-action"
                  v-for="res in typeaheadArr"
                  v-bind:key="res.domain"
                  @click.prevent="updateCompany(res)"
                >
                  <img :src="res.logo" style="width: 24px" />
                  {{ res.name }}
                </a>
              </div>
            </div>

            <div class="form-group">
              <label for="company-website" class="form-label">
                Company Website
              </label>
              <div class="input-group has-validation">
                <span class="input-group-text">https://</span>
                <input
                  id="company-website"
                  ref="companyWebsite"
                  type="text"
                  :class="[
                    'form-control',
                    companyValidation.url.valid
                      ? companyValidation.url.dirty
                        ? 'is-valid is-valid-lite'
                        : ''
                      : 'is-invalid',
                  ]"
                  v-model="company.url"
                  @blur="validateDomain"
                />
                <div class="invalid-feedback">Please enter a valid domain.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-center" v-if="company.logo">
            <img
              :src="company.logo"
              class="mb-2"
              style="max-width: 96px"
              v-if="company.logo"
            />

            <p class="small text-muted">{{ company.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <category-select
      v-model="categories"
      v-if="companyFilled"
      :site="site.siteid"
    ></category-select>

    <competitor-search
      v-model="competitors"
      v-if="categories.length >= 1"
    ></competitor-search>

    <div class="card mb-3" v-if="companyFilled && categories.length >= 1">
      <div class="card-body">
        <div class="d-flex justify-content-center align-items-center">
          <button
            class="btn btn-primary btn-md"
            :disabled="!companyValid"
            @click="saveCompany"
          >
            List Your Company
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.typeahead-container {
  position: relative;

  .typeahead-list {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 100;
  }
}
</style>

<script>
import CategorySelect from "./CategorySelect.vue";
import CompetitorSearch from "./CompetitorSearch.vue";
const isValidDomain = require("is-valid-domain");

const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export default {
  name: "add-your-company.company-form",
  props: {
    site: Object,
  },
  components: {
    CategorySelect,
    CompetitorSearch,
  },
  data() {
    return {
      typeaheadArr: [],
      showTypeahead: false,
      company: {
        name: null,
        url: null,
        logo: null,
      },
      categories: [],
      competitors: [],
      companyValidation: {
        name: {
          dirty: false,
          valid: true,
        },
        url: {
          dirty: false,
          valid: true,
          warning: null,
        },
      },
    };
  },
  methods: {
    saveCompany() {
      this.$emit("input", {
        company: this.company,
        categories: this.categories,
        competitors: this.competitors,
      });
      this.$emit("save-company");
    },
    validateCompanyName() {
      this.companyValidation.name.dirty = true;
      this.companyValidation.name.valid = true;
    },
    validateDomain() {
      if (!this.company.url) {
        this.companyValidation.url.valid = false;
        return;
      }

      this.companyValidation.url.dirty = true;
      if (this.company.url.startsWith("http://")) {
        this.company.url = this.company.url.replace("http://", "");
      } else if (this.company.url.startsWith("https://")) {
        this.company.url = this.company.url.replace("https://", "");
      }

      if (this.company.url.endsWith("/")) {
        this.company.url = this.company.url.substring(
          0,
          this.company.url.length - 1
        );
      }

      this.company.url = this.company.url.trim();

      if (!isValidDomain(this.company.url)) {
        this.companyValidation.url.valid = false;
        console.log("invalid");
        this.$nextTick(() => this.$refs.companyWebsite.focus());
      } else {
        this.companyValidation.url.valid = true;
      }
    },

    async typeahead() {
      if (!this.company.name || this.company.name.length < 3) {
        this.typeaheadArr = [];
        return;
      }

      let typeaheadResults = await fetch(
        `https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(
          this.company.name
        )}`
      ).then((resp) => resp.json());

      this.typeaheadArr = typeaheadResults;
    },
    updateCompany(res) {
      this.company.name = res.name;
      this.company.url = `www.${res.domain}`;
      this.company.logo = `${res.logo}?size=800`;
      this.showTypeahead = false;
      this.validateCompanyName();
      this.validateDomain();
    },
  },

  computed: {
    companyValid() {
      return (
        this.companyValidation.name.valid && this.companyValidation.url.valid
      );
    },
    companyFilled() {
      return (
        this.companyValidation.name.dirty && this.companyValidation.url.dirty
      );
    },
  },

  watch: {
    "company.name": debounce(function () {
      this.typeahead();
    }, 100),
  },

  mounted() {
    this.$refs.companyName.focus();
  },
};
</script>
